<template>
    <div class="d-flex flex-column align-center mt-10">
        <span class="title font-weight-bold primary--text mb-5">What would you like to do next?</span>
        <div class="d-flex justify-center">
            <v-btn
                v-if="applications.length"
                :to="{
                    name: 'app-open',
                    params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid, aid: applications[0].aid }
                }"
                color="secondary"
                class="mr-3"
                outlined
                text
                >Launch the Application</v-btn
            >
            <v-btn
                :to="{
                    name: 'snapshot-files',
                    params: {
                        oid: $route.params.oid,
                        sid: $route.params.sid,
                        iid: $route.params.iid,
                        snid: $route.params.snid,
                        localPath: currentFileLocalPath,
                        fileArea: fileAreaType
                    }
                }"
                color="secondary"
                outlined
                text
                class="mr-3"
                >Add Files</v-btn
            >
            <v-btn
                :to="{
                    name: 'space-user-management',
                    params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                }"
                color="secondary"
                outlined
                text
                class="mr-3"
                >Invite Users</v-btn
            >
            <v-btn
                :to="{
                    name: 'snapshot-overview',
                    params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                }"
                color="secondary"
                outlined
                text
                >Go to Space Overview</v-btn
            >
        </div>
        <div class="d-flex justify-center mt-3">
            <v-btn :to="{ name: 'home-dashboard', params: { oid: $route.params.oid } }" color="secondary" text class="caption font-weight-bold"
                >Return to Dashboard</v-btn
            >
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState('snapshotStore', ['applications', 'fileAreaType']),
        ...mapGetters('snapshotStore', ['currentFileLocalPath'])
    },
    beforeDestroy() {
        this.$store.dispatch('homeStore/resetCreateSpaceAndApp')
    }
}
</script>
